import React, {useState, useEffect, useCallback} from 'react';
import providerWeekSchedule from '../../containers/providerWeekSchedule';
import {Container, ButtonToolbar, Table, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ProviderSelect from '../ProviderSelect/ProviderSelect';
import './ProviderWeekSchedulesList.scss';
import {NotificationContainer} from 'react-notifications';
import apiClient from '../../utils/apiClient';

function ProviderWeekSchedulesList (props) {
  const {
    readProviderWeekSchedules,
    providerWeekSchedules,
    setPreloader,
  } = props;

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isRefresh, setRefresh] = useState(false);

  const getProviderWeekSchedules = useCallback(async (providerId) => {
    setPreloader(true);
    try {
      const providerWeekSchedules = await apiClient.getProviderWeekSchedules(providerId);
      return providerWeekSchedules;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    if (!selectedProvider || !selectedProvider.value) {
      readProviderWeekSchedules({items: [], totalCount: 0});
      return;
    }

    getProviderWeekSchedules(selectedProvider.value).then((response) => 
      readProviderWeekSchedules(response)
    );
  }, [
    setPreloader,
    getProviderWeekSchedules,
    readProviderWeekSchedules,
    selectedProvider,
    isRefresh
  ]);

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <ProviderSelect
              className="filter-provider mr-2 mt-2"
              value={selectedProvider}
              onChange={(provider) => setSelectedProvider(provider)}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload data">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">Index</th>
              <th scope="col">Weekday</th>
              <th scope="col">From Time</th>
              <th scope="col">To Time</th>
            </tr>
          </thead>
          <tbody>
            {
              !providerWeekSchedules
              ? <tr><td>Loading...</td></tr>
              : providerWeekSchedules.length 
                ? providerWeekSchedules.map(el => (
                  <tr key={el.providerId + el.index + el.fromTime}>
                    <td>{el.index}</td>
                    <td>{el.weekday}</td>
                    <td>{el.fromTime}</td>
                    <td>{el.toTime}</td>
                  </tr>
                  ))
                : <tr><td colSpan="4" className="text-center">No week schedule yet</td></tr>
            }
          </tbody>
        </Table>
      </div>
    </div>
  );
}
export default providerWeekSchedule(ProviderWeekSchedulesList);