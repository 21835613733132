import React from 'react';
import AppointmentsList from '../../components/AppointmentsList/AppointmentsList';
import './Appointments.scss';

function Appointments (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Appointments</h3>
      </div>
      <div className="row">
        <AppointmentsList />
      </div>
    </div>
  );
}
export default Appointments;