import {
  APPOINTMENTS_READNG,
  APPOINTMENT_CREATION,
  APPOINTMENT_UPDATING
} from '../actions/types';

const INITIAL_STATE = {
  items: null,
  totalCount: 1
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APPOINTMENTS_READNG:
      return {
        ...state,
        items: action.appointmentList.items,
        totalCount: action.appointmentList.totalCount
      };

    case APPOINTMENT_CREATION:
      return {
        ...state,
        items: [...state.items, action.appointment],
      };

    case APPOINTMENT_UPDATING:
      return {
        ...state,
        items: state.items.map(appointment => {
          return (appointment.id === action.appointment.id)
            ? action.appointment
            : appointment
        })
      };

    default:
      return state
  }
}