import {connect} from 'react-redux';
import * as ApiClientActions from '../actions/apiClients';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = state => ({
  auth: state.auth,
  apiClients: state.apiClients.items,
  totalCount: state.apiClients.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readApiClients: apiClientList => dispatch(ApiClientActions.readApiClients(apiClientList)),
  createApiClient: apiClient => dispatch(ApiClientActions.createApiClient(apiClient)),
  updateApiClient: apiClient => dispatch(ApiClientActions.updateApiClient(apiClient)),
  deleteApiClient: apiClient => dispatch(ApiClientActions.deleteApiClient(apiClient)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
