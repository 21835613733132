import React from 'react';
import IntegrationsList from '../../components/IntegrationsList/IntegrationsList';
import './Integrations.scss';

function Integrations (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Integrations</h3>
      </div>
      <div className="row">
        <IntegrationsList />
      </div>
    </div>
  );
}
export default Integrations;