import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
//import InputMask from 'react-input-mask';

import './Input.scss';

const defaultProps = {
  className: null,
  input: {
    value: '',
  },
  meta: {
    touched: false,
    error: null,
    active: false,
  },
};

const propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    active: PropTypes.bool,
  }),
};

const Input = (props) => {
  const {
    className,
    placeholder,
    input,
    meta,
    type,
    disabled,
    max,
    maxLength,
    getRef = () => {},
  } = props;

  const {touched, error, active} = meta;
  let {value} = input;
  
  const style = classnames({
    [className]: className,
    input: true,
    input_focus: active,
    input_value: value,
    input_error: touched && error,
  });

  return (
    <div className={style}>
      <input
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        max={max}
        maxLength={maxLength}
        ref={input => getRef(input)}
        {...input}
      />
      <div className="input__error-message">{error}</div>
    </div>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;