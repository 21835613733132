import React from 'react';
import main from '../../containers/main';
import { OverlayTrigger, Popover, Button} from 'react-bootstrap';
import './styles.scss';
import Notification from '../Icons/Notification/Notification';

const NotificationTooltip = (props) => {
  const {user} = props;
  const handleView = async (item, index) => {
    //
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{user.notifications ? user.notifications.length : 0} Notifications</Popover.Title>
      <Popover.Content>
        {user.notifications && user.notifications.length
          ? user.notifications.map((item, index) => (
              <span key={index}>{item.message} <Button type="info" onClick={() => handleView(item, index)}> View</Button></span>
            ))
          : null
        }
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      {user.notifications && user.notifications.length
        ? <div className="pulsating-circle"></div>
        : null
      }
      <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={popover}>
        <button className="tooltip-btn"><Notification /></button>
      </OverlayTrigger>
    </>
  );
}

export default main(NotificationTooltip);
