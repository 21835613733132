import React, {useState, useEffect, useCallback} from 'react';
import integration from '../../containers/integration';
import { Container, ButtonToolbar, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../Pagination/Pagination';
import './IntegrationsList.scss';
import {NotificationContainer} from 'react-notifications';
import apiClient from '../../utils/apiClient';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function IntegrationsList (props) {
  const {
    readIntegrations,
    integrations,
    totalCount,
    setPreloader,
  } = props;

  const [integrationId, setIntegrationId] = useState(0);
  const [integrationUid, setIntegrationUid] = useState('');
  const [integrationLogin, setIntegrationLogin] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getIntegrations = useCallback(async (filter, order, pagination) => {
    setPreloader(true);
    try {
      const integrations = await apiClient.getIntegrations(filter, order, pagination);
      return integrations;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    getIntegrations(
      {
        id: integrationId,
        uid: integrationUid,
        login: integrationLogin
      },
      null,
      {pageNum, pageSize}
    ).then((response) => readIntegrations(response));
  }, [
    getIntegrations,
    integrationId,
    integrationUid,
    integrationLogin,
    pageNum,
    pageSize,
    readIntegrations,
    isRefresh
  ]);

  let filterTimeout;
  const onFilterChange = (callback) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() =>{
      callback();
      setPageNum(1);
    }, 750);
  }

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <input
              className="filter-id mr-2 mt-2"
              type="search"
              placeholder="ID..."
              onChange={(e) => onFilterChange(setIntegrationId.bind(null, e.target.value))}
            />
            <input
              className="filter-uid mr-2 mt-2"
              type="search"
              placeholder="UID..."
              onChange={(e) => onFilterChange(setIntegrationUid.bind(null, e.target.value))}
            />
            <input
              className="filter-email mr-2 mt-2"
              type="search"
              placeholder="Login..."
              onChange={(e) => onFilterChange(setIntegrationLogin.bind(null, e.target.value))}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload data">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">UID</th>
              <th scope="col">Login</th>
              <th scope="col">PMS</th>
              <th scope="col">Practice(s)</th>
              <th scope="col">Satus</th>
              <th scope="col">Synced At</th>
            </tr>
          </thead>
          <tbody>
            {
              !integrations
              ? <tr><td>Loading...</td></tr>
              : integrations.length 
                ? integrations.map(el => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td className="nowrap">
                      <span title={el.uid}>{el.uid.substr(0, 4)} ... {el.uid.substr(el.uid.length - 4)}</span>
                      <CopyToClipboard text={el.uid} className="btn-copy ml-2">
                        <Button variant="light" title="Copy to clipboard"><FontAwesomeIcon icon="clipboard"/></Button>
                      </CopyToClipboard>
                    </td>
                    <td className="nowrap">
                      {el.login}
                      <CopyToClipboard text={el.login} className="btn-copy ml-2">
                        <Button variant="light" title="Copy to clipboard"><FontAwesomeIcon icon="clipboard"/></Button>
                      </CopyToClipboard>
                    </td>
                    <td>{el.pmsName}</td>
                    <td>{el.practices.map(practice => (
                      <div key={practice.id}>
                        {practice.name}
                      </div>
                    ))}</td>
                    <td>{el.status}</td>
                    <td>{el.syncedAt}</td>
                  </tr>
                  ))
                : <tr><td colSpan="7" className="text-center">No integrations yet</td></tr>
            }
          </tbody>
        </Table>
      
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}
export default integration(IntegrationsList);