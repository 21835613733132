import React, {useState, useEffect, useCallback} from 'react';
import patient from '../../containers/patient';
import {Container, ButtonToolbar, Table, Button, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PracticeSelect from '../PracticeSelect/PracticeSelect';
import Pagination from '../Pagination/Pagination';
import './PatientsList.scss';
import {NotificationContainer} from 'react-notifications';
import apiClient from '../../utils/apiClient';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Info from '../Info/Info';

function PatientsList (props) {
  const {
    readPatients,
    patients,
    totalCount,
    setPreloader,
  } = props;

  const [selectedPractice, setSelectedPractice] = useState(null);
  const [patientId, setPatientId] = useState(0);
  const [patientExternalId, setPatientExternalId] = useState('');
  const [patientSmylenId, setPatientSmylenId] = useState(0);
  const [patientName, setPatientName] = useState('');
  const [isWriteback, setIsWriteback] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getPatients = useCallback(async (filter, order, pagination) => {
    setPreloader(true);
    try {
      const patients = await apiClient.getPatients(filter, order, pagination);
      return patients;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    getPatients(
      {
        id: patientId,
        externalId: patientExternalId,
        smylenId: patientSmylenId,
        practiceId: selectedPractice ? selectedPractice.value : null,
        fullName: patientName,
        isWriteback,
      },
      null,
      {
        pageNum,
        pageSize
      }
    ).then((response) => 
      readPatients(response)
    );
  }, [
    getPatients,
    pageNum,
    pageSize,
    readPatients,
    patientId,
    patientExternalId,
    patientSmylenId,
    patientName,
    isWriteback,
    selectedPractice,
    isRefresh
  ]);

  let filterTimeout;
  const onFilterChange = (callback) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() =>{
      callback();
      setPageNum(1);
    }, 750);
  }

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <input
              className="filter-id mr-2 mt-2"
              type="search"
              placeholder="ID..."
              onChange={(e) => onFilterChange(setPatientId.bind(null, e.target.value))}
            />
            <input
              className="filter-external-id mr-2 mt-2"
              type="search"
              placeholder="Ext ID..."
              onChange={(e) => onFilterChange(setPatientExternalId.bind(null, e.target.value))}
            />
            <input
              className="filter-smylen-id mr-2 mt-2"
              type="search"
              placeholder="Smyl ID..."
              onChange={(e) => onFilterChange(setPatientSmylenId.bind(null, e.target.value))}
            />
            <input
              className="filter-patient-name mr-2 mt-2"
              type="search"
              placeholder="Full Name..."
              onChange={(e) => onFilterChange(setPatientName.bind(null, e.target.value))}
            />
            <ToggleButtonGroup
              type="checkbox"
              className="filter-toggle mr-2 mt-2"
              onChange={(isWriteback) => setIsWriteback(isWriteback.length && isWriteback[0])}
            >
              <ToggleButton value={true} variant="light">Writebacks</ToggleButton>
            </ToggleButtonGroup>
            <PracticeSelect
              className="filter-practice mr-2 mt-2"
              value={selectedPractice}
              onChange={(practice) => setSelectedPractice(practice)}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload list">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">External ID</th>
              <th scope="col">Smylen ID</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Middle Name</th>
              <th scope="col">Gender</th>
              <th scope="col">Birthday</th>
              <th scope="col">Writeback</th>
              <th scope="col">Practice ID</th>
            </tr>
          </thead>
          <tbody>
            {
              !patients
              ? <tr><td>Loading...</td></tr>
              : patients.length 
                ? patients.map(el => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>
                      {
                        el.externalId && el.externalId.trim().length >= 36 ? (
                          <div>
                            <span title={el.externalId}>{el.externalId.substr(0, 4) + '...'}</span>
                            <CopyToClipboard text={el.externalId} className="btn-copy ml-2">
                              <Button variant="light" title="Copy to clipboard"><FontAwesomeIcon icon="clipboard"/></Button>
                            </CopyToClipboard>
                          </div>
                        ) : (
                          <span>{el.externalId}</span>
                        )
                      }
                    </td>
                    <td>{el.smylenId}</td>
                    <td>{el.firstName}</td>
                    <td>{el.lastName}</td>
                    <td>{el.middleName}</td>
                    <td>{el.gender}</td>
                    <td>{el.birthdate}</td>
                    <td style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div style={{display: 'inline'}}>
                        {el.isWriteback ? <input type="checkbox" checked={el.isWriteback} disabled/> : null}
                      </div>
                      {el.writebackResult ? <Info text={el.writebackResult}/> : null}
                    </td>
                    <td>{el.practiceId}</td>
                  </tr>
                  ))
                : <tr><td colSpan="10" className="text-center">No patients yet</td></tr>
            }
          </tbody>
        </Table>
      
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}
export default patient(PatientsList);