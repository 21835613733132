const isEmailReg = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const numberReg = /[0-9]/;
const letterReg = /[a-z]/;
const capitalReg = /[A-Z]/;
const lettersReg = /[a-zA-Z]/;

const email = value => (
  (value && value.trim() && !isEmailReg.test(value.trim())
    ? 'Incorrect email'
    : null)
);

const existsNumber = value => (
  (value.trim() && !numberReg.test(value.trim())
    ? 'Password must exist at least 1 number'
    : null)
);

const existsLetter = value => (
  (value.trim() && !letterReg.test(value.trim())
    ? 'Password must exist at least 1 letter'
    : null)
);

const notExistsLetter = value => (
  (value.trim() && !lettersReg.test(value.trim())
    ? null
    : `Password mustn't exist letters`)
);

const existsCapital = value => (
  (value.trim() && !capitalReg.test(value)
    ? 'Password must exist at least 1 capital letter'
    : null)
);

const isEmpty = value => (
  !value || (typeof value === 'string' && !value.trim())
);

const required = value => (
  isEmpty(value) ? 'Required' : null
);

const confirm = (value, all) => (
  (all.password === all.confirmpassword) ? undefined : 'Password mismatched'
);

const passLength = value => (
  value.length && value.length >= 10 ? null : 'Password must be at least 10 symbols'
);

const usernameLength = value => (
  value.length && value.trim().length >= 6 ? null : 'Username must be at least 6 symbols'
);

export {
  email,
  required,
  confirm,
  passLength,
  usernameLength,
  existsNumber,
  existsLetter,
  existsCapital,
  notExistsLetter
};
