import React, {useState, useEffect, useCallback} from 'react';
import practice from '../../containers/practice';
import { Container, ButtonToolbar, ButtonGroup, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../Pagination/Pagination';
// import onUpdateIntegration from './onUpdateIntegration';
import './PracticesList.scss';
// import createNotification from '../Notification/Notification';
import {NotificationContainer} from 'react-notifications';
import apiClient from '../../utils/apiClient';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function PracticesList (props) {
  const {
    readPractices,
    // updateIntegration,
    practices,
    totalCount,
    setPreloader,
  } = props;

  //const [integration, setIntegration] = useState('');
  const [practiceId, setPracticeId] = useState(0);
  const [practiceUid, setPracticeUid] = useState('');
  const [practiceSmylenId, setPracticeSmylenId] = useState(0);
  const [practiceName, setPracticeName] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getPractices = useCallback(async (filter, order, pagination) => {
    setPreloader(true);
    try {
      const practices = await apiClient.getPractices(filter, order, pagination);
      return practices;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    getPractices(
      {
        id: practiceId,
        uid: practiceUid,
        smylenId: practiceSmylenId,
        name: practiceName
      },
      null,
      {
        pageNum,
        pageSize
      }
    ).then((response) => readPractices(response));
  }, [
    getPractices,
    pageNum,
    pageSize,
    readPractices,
    practiceId,
    practiceUid,
    practiceSmylenId,
    practiceName,
    isRefresh
  ]);

//  const createPractice = () => {};

  let filterTimeout;
  const onFilterChange = (callback) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() =>{
      callback();
      setPageNum(1);
    }, 750);
  }

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <input
              className="filter-id mr-2 mt-2"
              type="search"
              placeholder="ID..."
              onChange={(e) => onFilterChange(setPracticeId.bind(null, e.target.value))}
            />
            <input
              className="filter-uid mr-2 mt-2"
              type="search"
              placeholder="UID..."
              onChange={(e) => onFilterChange(setPracticeUid.bind(null, e.target.value))}
            />
            <input
              className="filter-smylen-id mr-2 mt-2"
              type="search"
              placeholder="Smyl ID..."
              onChange={(e) => onFilterChange(setPracticeSmylenId.bind(null, e.target.value))}
            />
            <input
              className="filter-practice-name mr-2 mt-2"
              type="search"
              placeholder="Name..."
              onChange={(e) => onFilterChange(setPracticeName.bind(null, e.target.value))}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload data">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">UID</th>
              <th scope="col">Smylen ID</th>
              <th scope="col">Name</th>
              <th scope="col">Address 1</th>
              <th scope="col">Address 2</th>
              <th scope="col">City</th>
              <th scope="col">State</th>
              <th scope="col">Zipcode</th>
              <th scope="col">Timezone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              !practices
              ? <tr><td>Loading...</td></tr>
              : practices.length 
                ? practices.map(el => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td className="nowrap">
                      <span title={el.uid}>{el.uid.substr(0, 4)} ... {el.uid.substr(el.uid.length - 4)}</span>
                      <CopyToClipboard text={el.uid} className="btn-copy ml-2">
                        <Button variant="light" title="Copy to clipboard"><FontAwesomeIcon icon="clipboard"/></Button>
                      </CopyToClipboard>
                    </td>
                    <td>{el.smylenId}</td>
                    <td>{el.name}</td>
                    <td>{el.address1}</td>
                    <td>{el.address2}</td>
                    <td>{el.city}</td>
                    <td>{el.state}</td>
                    <td>{el.zipcode}</td>
                    <td>{el.timezone}</td>
                    <td className="btn-row">
                      <ButtonToolbar className="actions">
                        <ButtonGroup>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </td>
                  </tr>
                  ))
                : <tr><td colSpan="11" className="text-center">No practices yet</td></tr>
            }
          </tbody>
        </Table>
      
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}
export default practice(PracticesList);