import {connect} from 'react-redux';
import * as ProviderActions from '../actions/providers';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  providers: state.providers.items,
  totalCount: state.providers.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readProviders: providers => dispatch(ProviderActions.readProviders(providers)),
  createProvider: provider => dispatch(ProviderActions.createProvider(provider)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
