import React from 'react';
import UpdatePasswordForm from '../../UpdatePasswordForm/UpdatePasswordForm';
import { Modal } from 'react-bootstrap';
import main from '../../../containers/main';

const ModalUpdatePass = (props) => {
  const {
    handleClose,
    show,
    handleUpdateUser
  } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UpdatePasswordForm onSubmit={handleUpdateUser}></UpdatePasswordForm>
      </Modal.Body>
    </Modal>
  );
};

export default main(ModalUpdatePass);