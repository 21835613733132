import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import {Overlay, Tooltip} from 'react-bootstrap';

class Info extends React.Component {
  constructor(props) {
    super(props);

    this.state = {show: false};
    this.target = React.createRef();
  }
  
  setShow(value) {
    this.setState({show: value});
  }

  render() {
    const buttonStyle = {
      padding: '0 .2rem'
    };

    return (
      <>
        <button ref={this.target} onClick={() => this.setShow(!this.state.show)} style={buttonStyle}>
          <FontAwesomeIcon icon="info-circle" title={this.props.text}/>
        </button>
        {/* <Overlay target={this.target.current} show={this.state.show} placement="top">
          <Tooltip {...this.props}>
            {this.props.text}
          </Tooltip>
        </Overlay> */}
      </>
    );
  }
}

export default Info;