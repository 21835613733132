import React from 'react';
import ProvidersList from '../../components/ProvidersList/ProvidersList';
import './Providers.scss';

function Providers (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Providers</h3>
      </div>
      <div className="row">
        <ProvidersList />
      </div>
    </div>
  );
}
export default Providers;