export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_USER_UPDATING = 'AUTH_USER_UPDATING';

export const USERS_READING = 'USER_READING';
export const USER_CREATION = 'USER_CREATION';
export const USER_READING = 'USER_READING';
export const USER_UPDATING = 'USER_UPDATING';
export const USER_DELETION = 'USER_DELETION';
export const USER_EDITING = 'USER_EDITING';

export const API_CLIENTS_READING = 'API_CLIENTS_READING';
export const API_CLIENT_CREATION = 'API_CLIENTS_CREATION';
export const API_CLIENT_READING = 'API_CLIENT_READING';
export const API_CLIENT_UPDATING = 'API_CLIENTS_UPDATING';
export const API_CLIENT_DELETION = 'API_CLIENTS_DELETION';

export const INTEGRATIONS_READNG = 'INTEGRATIONS_READNG';
export const INTEGRATION_CREATION = 'INTEGRATION_CREATION';
export const INTEGRATION_READING = 'INTEGRATION_READING';
export const INTEGRATION_UPDATING = 'INTEGRATION_UPDATING';
export const INTEGRATION_DELETION = 'INTEGRATION_DELETION';

export const PRACTICES_READNG = 'PRACTICES_READNG';
export const PRACTICE_CREATION = 'PRACTICE_CREATION';
export const PRACTICE_READING = 'PRACTICE_READING';
export const PRACTICE_UPDATING = 'PRACTICE_UPDATING';
export const PRACTICE_DELETION = 'PRACTICE_DELETION';

export const OPERATORIES_READNG = 'OPERATORIES_READNG';
export const OPERATORY_CREATION = 'OPERATORY_CREATION';
export const OPERATORY_READING = 'OPERATORY_READING';
export const OPERATORY_UPDATING = 'OPERATORY_UPDATING';
export const OPERATORY_DELETION = 'OPERATORY_DELETION';

export const PROVIDERS_READNG = 'PROVIDERS_READNG';
export const PROVIDER_CREATION = 'PROVIDER_CREATION';
export const PROVIDER_READING = 'PROVIDER_READING';
export const PROVIDER_UPDATING = 'PROVIDER_UPDATING';
export const PROVIDER_DELETION = 'PROVIDER_DELETION';

export const PRACTICE_WEEK_SCHEDULES_READNG = 'PRACTICE_WEEK_SCHEDULES_READNG';
export const PRACTICE_WORK_SCHEDULES_READNG = 'PRACTICE_WORK_SCHEDULES_READNG';
export const PROVIDER_WEEK_SCHEDULES_READNG = 'PROVIDER_WEEK_SCHEDULES_READNG';
export const PROVIDER_WORK_SCHEDULES_READNG = 'PROVIDER_WORK_SCHEDULES_READNG';

export const PATIENTS_READNG = 'PATIENTS_READNG';
export const PATIENT_CREATION = 'PATIENT_CREATION';
export const PATIENT_READING = 'PATIENT_READING';
export const PATIENT_UPDATING = 'PATIENT_UPDATING';
export const PATIENT_DELETION = 'PATIENT_DELETION';

export const APPOINTMENTS_READNG = 'APPOINTMENTS_READNG';
export const APPOINTMENT_CREATION = 'APPOINTMENT_CREATION';
export const APPOINTMENT_READING = 'APPOINTMENT_READING';
export const APPOINTMENT_UPDATING = 'APPOINTMENT_UPDATING';
export const APPOINTMENT_DELETION = 'APPOINTMENT_DELETION';

export const SET_PRELOADER = 'SET_PRELOADER';
export const SET_FORM_STATE = 'SET_FORM_STATE';
export const SET_LOGOUT = 'SET_LOGOUT';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
