import {createStore, combineReducers, applyMiddleware} from 'redux';
import {reducer as formReducer } from 'redux-form';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import application from './application';
import auth from './auth';
import users from './users';
import apiClients from './apiClients';
import integrations from './integrations';
import practices from './practices';
import operatories from './operatories';
import providers from './providers';
import practiceWeekSchedules from './practiceWeekSchedules';
import practiceWorkSchedules from './practiceWorkSchedules';
import providerWeekSchedules from './providerWeekSchedules';
import providerWorkSchedules from './providerWorkSchedules';
import patients from './patients';
import appointments from './appointments';

const rootReducer = combineReducers({
  application,
  auth,
  users,
  apiClients,
  integrations,
  practices,
  operatories,
  providers,
  practiceWeekSchedules,
  practiceWorkSchedules,
  providerWeekSchedules,
  providerWorkSchedules,
  patients,
  appointments,
  form: formReducer,
});

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
);