import {
  PROVIDER_WEEK_SCHEDULES_READNG,
} from '../actions/types';

const INITIAL_STATE = {
  items: null,
  totalCount: 1
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROVIDER_WEEK_SCHEDULES_READNG:
      return {
        ...state,
        items: action.providerWeekScheduleList.items,
        totalCount: action.providerWeekScheduleList.totalCount
      };
    default:
      return state
  }
}