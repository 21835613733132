import React, {useState, useEffect, useCallback} from 'react';
import operatory from '../../containers/operatory';
import {Container, ButtonToolbar, Table, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PracticeSelect from '../PracticeSelect/PracticeSelect';
import Pagination from '../Pagination/Pagination';
import './OperatoriesList.scss';
import {NotificationContainer} from 'react-notifications';
import apiClient from '../../utils/apiClient';

function OperatoriesList (props) {
  const {
    readOperatories,
    operatories,
    totalCount,
    setPreloader,
  } = props;

  const [selectedPractice, setSelectedPractice] = useState(null);
  const [operatoryId, setOperatoryId] = useState(0);
  const [operatoryExternalId, setOperatoryExternalId] = useState('');
  const [operatoryTitle, setOperatoryTitle] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getOperatories = useCallback(async (filter, order, pagination) => {
    setPreloader(true);
    try {
      const operatories = await apiClient.getOperatories(filter, order, pagination);
      return operatories;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    getOperatories(
      {
        id: operatoryId,
        externalId: operatoryExternalId,
        practiceId: selectedPractice ? selectedPractice.value : null,
        title: operatoryTitle
      },
      null,
      {
        pageNum,
        pageSize
      }
    ).then((response) => 
      readOperatories(response)
    );
  }, [
    getOperatories,
    pageNum,
    pageSize,
    readOperatories,
    operatoryId,
    operatoryExternalId,
    operatoryTitle,
    selectedPractice,
    isRefresh
  ]);

  let filterTimeout;
  const onFilterChange = (callback) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() =>{
      callback();
      setPageNum(1);
    }, 750);
  }

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <input
              className="filter-id mr-2 mt-2"
              type="search"
              placeholder="ID..."
              onChange={(e) => onFilterChange(setOperatoryId.bind(null, e.target.value))}
            />
            <input
              className="filter-external-id mr-2 mt-2"
              type="search"
              placeholder="Ext ID..."
              onChange={(e) => onFilterChange(setOperatoryExternalId.bind(null, e.target.value))}
            />
            <input
              className="filter-provider-name mr-2 mt-2"
              type="search"
              placeholder="Full Name..."
              onChange={(e) => onFilterChange(setOperatoryTitle.bind(null, e.target.value))}
            />
            <PracticeSelect
              className="filter-practice mr-2 mt-2"
              value={selectedPractice}
              onChange={(practice) => setSelectedPractice(practice)}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload data">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">External ID</th>
              <th scope="col">Title</th>
              <th scope="col">Practice ID</th>
            </tr>
          </thead>
          <tbody>
            {
              !operatories
              ? <tr><td>Loading...</td></tr>
              : operatories.length 
                ? operatories.map(el => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.externalId}</td>
                    <td>{el.title}</td>
                    <td>{el.practiceId}</td>
                  </tr>
                  ))
                : <tr><td colSpan="4" className="text-center">No operatories yet</td></tr>
            }
          </tbody>
        </Table>
      
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}
export default operatory(OperatoriesList);