import React from 'react';
import ApiClientsList from '../../components/ApiClientsList/ApiClientsList';
import './ApiClients.scss';

function ApiClients (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>API Clients</h3>
      </div>
      <div className="row">
        <ApiClientsList />
      </div>
    </div>
  );
}

export default ApiClients;