import React, {useEffect} from 'react';
import './Layout.scss';
import Preloader from '../../components/Preloader/Preloader';
import layout from '../../containers/layout';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import Navigation from '../../components/Navigation/Navigation';
import Content from '../../components/Content/Content';
import Login from '../../pages/Login/Login';
import Logout from '../../pages/Logout/Logout';
import ApiClients from '../../pages/ApiClients/ApiClients';
import Integrations from '../../pages/Integrations/Integrations';
import Providers from '../../pages/Providers/Providers';
import PracticeWeekSchedule from '../../pages/PracticeWeekSchedule/PracticeWeekSchedule';
import PracticeWorkSchedule from '../../pages/PracticeWorkSchedule/PracticeWorkSchedule';
import ProviderWeekSchedule from '../../pages/ProviderWeekSchedule/ProviderWeekSchedule';
import ProviderWorkSchedule from '../../pages/ProviderWorkSchedule/ProviderWorkSchedule';
import Practices from '../../pages/Practices/Practices';
import Operatories from '../../pages/Operatories/Operatories';
import Patients from '../../pages/Patients/Patients';
import Appointments from '../../pages/Appointments/Appointments';
import Users from '../../pages/Users/Users';
import { Container } from 'react-bootstrap';
import Reset from '../../pages/ResetPassword/ResetPassword';
import Profile from '../../pages/Profile/Profile';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

const Layout = (props) => {
  const {preloader,
    history,
    signIn
  } = props;

  useEffect(() => {
    const token = localStorage.getItem('token');
    let user = null;
    try {
      user = JSON.parse(localStorage.getItem('user'));
    } catch {
      // Do nothing.
    }
    if (token && user) {
      signIn(token, user);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <div className="layout">
      {preloader 
        ? <Preloader />
        : null
      }
      <div className="layout__wrapper">
        <div className="layout__content">
          <Container fluid>
            <Router history={history}>
              <Navigation history={history}/>
              <Content>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/login" component={Login} />
                  <Route path="/logout" component={Logout} />
                  <Route path="/reset" component={Reset} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/users'} component={Users} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/apiClients'} component={ApiClients} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/integrations'} component={Integrations} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/practices'} component={Practices} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/operatories'} component={Operatories} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/providers'} component={Providers} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/practice-week-schedule'} component={PracticeWeekSchedule} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/practice-work-schedule'} component={PracticeWorkSchedule} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/provider-week-schedule'} component={ProviderWeekSchedule} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/provider-work-schedule'} component={ProviderWorkSchedule} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/patients'} component={Patients} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/appointments'} component={Appointments} />
                  <PrivateRoute history={history} access={['admin', 'user']} path={'/profile'} component={Profile} />
                </Switch>
              </Content>
            </Router>
          </Container>  
        </div>
      </div>
    </div>
  );
}

export default layout(Layout);