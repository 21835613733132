import React, { useState } from 'react';
import Input from '../Input/Input';
// import Button from '../Button/Button';
// import { Button as ButtonB, ButtonToolbar, Col } from 'react-bootstrap';

import {Field, reduxForm} from 'redux-form';
import {required, email} from '../../utils/validation';
import './LoginForm.scss';
import Modal from './Modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let LoginForm = (props) => {
  const {
    handleSubmit,
    submitError
  } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <div className="card login-form">
      <article className="card-body">
        <h4 className="card-title text-center mb-4 mt-1">Sign in</h4>
        <hr />
        {submitError
          ? <div className="alert alert-danger" role="alert">
            {submitError}
          </div>
          : null
        }
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"> <FontAwesomeIcon icon="user" /> </span>
              </div>
              <Field
                type="email"
                name="login"
                className=""
                placeholder="Email"
                component={Input}
                validate={[required, email]}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"> <FontAwesomeIcon icon="lock" /> </span>
              </div>
              <Field
                type="password"
                name="password"
                className=""
                placeholder="******"
                component={Input}
                validate={[required]}
              />
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block"> Login  </button>
          </div>
          {/* <p className="text-center"><a href="#" onClick={handleShow} className="btn">Forgot password?</a></p> */}
        </form>
      </article>
      <Modal show={show} handleClose={handleClose}></Modal>
    </div>
  );
}

LoginForm = reduxForm({
  form: 'loginForm'
})(LoginForm)

export default LoginForm;