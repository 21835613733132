import {
  INTEGRATIONS_READNG,
  INTEGRATION_CREATION,
  INTEGRATION_UPDATING
} from '../actions/types';

const INITIAL_STATE = {
  items: null,
  totalCount: 1
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INTEGRATIONS_READNG:
      return {
        ...state,
        items: action.integrationList.items,
        totalCount: action.integrationList.totalCount
      };

    case INTEGRATION_CREATION:
      return {
        ...state,
        items: [...state.items, action.integration],
      };

    case INTEGRATION_UPDATING:
      return {
        ...state,
        items: state.items.map(integration => {
          return (integration.id === action.integration.id)
            ? action.integration
            : integration
        })
      };

    default:
      return state
  }
}