import React from 'react';
import OperatoriesList from '../../components/OperatoriesList/OperatoriesList';
import './Operatories.scss';

function Operatories(props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Operatories</h3>
      </div>
      <div className="row">
        <OperatoriesList />
      </div>
    </div>
  );
}

export default Operatories;