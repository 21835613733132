import {connect} from 'react-redux';
import * as PracticeWorkScheduleActions from '../actions/practiceWorkSchedules';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  practiceWorkSchedules: state.practiceWorkSchedules.items,
  totalCount: state.practiceWorkSchedules.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readPracticeWorkSchedules: practiceWorkSchedules => dispatch(PracticeWorkScheduleActions.readPracticeWorkSchedules(practiceWorkSchedules)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
