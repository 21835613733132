import React, {useRef, useEffect} from 'react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import main from '../../containers/main';
import {Field, reduxForm} from 'redux-form';
import {required} from '../../utils/validation';
import './ApiClientForm.scss';

let ApiClientForm = (props) => {
  const { handleSubmit } = props;

  let firstEl = useRef(null);

  useEffect(() => {
    firstEl.focus();
  });

  return (
    <div className="user-form">
      <form onSubmit={handleSubmit} className="col-sm-12" noValidate autoComplete="off">
        <div className="form-group row">
          <label className="col-sm-2 col-form-label" htmlFor="name">Company</label>
          <div className="col-sm-10">
            <Field
              name="company"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              getRef={input => {
                firstEl = input;
              }}
            />
          </div>
        </div>
        
        <Button
          className="btn btn-primary submit-btn"
          type="submit"
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
ApiClientForm = reduxForm({
  form: 'ApiClientForm'
})(ApiClientForm)

export default main(ApiClientForm);