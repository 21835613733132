import config from '../config';
import { default as jaysonBrowserClient } from 'jayson/lib/client/browser';

const callServer = function(request, callback) {
  const options = {
    method: 'POST',
    body: request,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const token = localStorage.getItem('token');
  if (token) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }

  fetch(config.apiUrl, options)
    .then(function(res) {
      return res.json();
    })
    .then(function (json) {
      if (json.error && json.error.code === 401) {
        window.location = '/logout';
      }
      return JSON.stringify(json);
    })
    .then(function(text) {
      callback(null, text);
    })
    .catch(function(err) { 
      callback(err);
    });
};

const client = jaysonBrowserClient(callServer, {
  // other options go here
});

function login(username, password) {
  return new Promise((resolve, reject) => {
    client.request('login', [username, password], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getUsers(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getUsers', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function addUser(data) {
  return new Promise((resolve, reject) => {
    client.request('addUser', [data], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function editUser(id, data) {
  return new Promise((resolve, reject) => {
    client.request('editUser', [id, data], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function deleteUser(id) {
  return new Promise((resolve, reject) => {
    client.request('deleteUser', [id], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getApiClients(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getApiClients', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function addApiClient(data) {
  return new Promise((resolve, reject) => {
    client.request('addApiClient', [data], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function deleteApiClient(id) {
  return new Promise((resolve, reject) => {
    client.request('deleteApiClient', [id], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function regenerateApiKey(id) {
  return new Promise((resolve, reject) => {
    client.request('regenerateApiKey', [id], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getIntegrations(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getIntegrations', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getPractices(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getPractices', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getOperatories(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getOperatories', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getProviders(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getProviders', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getPatients(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getPatients', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getAppointments(filter, order, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getAppointments', [filter, order, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getPracticeWeekSchedules(practiceId) {
  return new Promise((resolve, reject) => {
    client.request('getPracticeWeekSchedules', [practiceId], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getPracticeWorkSchedules(practiceId, filter, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getPracticeWorkSchedules', [practiceId, filter, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getProviderWeekSchedules(providerId) {
  return new Promise((resolve, reject) => {
    client.request('getProviderWeekSchedules', [providerId], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

function getProviderWorkSchedules(providerId, filter, pagination) {
  return new Promise((resolve, reject) => {
    client.request('getProviderWorkSchedules', [providerId, filter, pagination], (err, error, result) => {
      if (err) throw err;
      if (error) return reject(new Error(error.message));
      return resolve(result);
    });
  });
}

export default {
  login,
  getUsers,
  addUser,
  editUser,
  deleteUser,
  getApiClients,
  addApiClient,
  deleteApiClient,
  regenerateApiKey,
  getIntegrations,
  getPractices,
  getOperatories,
  getProviders,
  getPatients,
  getAppointments,
  getPracticeWeekSchedules,
  getPracticeWorkSchedules,
  getProviderWeekSchedules,
  getProviderWorkSchedules
}