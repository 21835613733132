import React from 'react';
import { Provider } from 'react-redux';
import store from './reducers/store';
import Layout from './components/Layout/Layout';
import { createBrowserHistory } from "history";
import './App.scss';
import './assets/scss/main.scss';
import "react-datepicker/dist/react-datepicker.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser, faLock, faPlus, faClipboard, faInfoCircle, faTrash,
  faKey, faSync, faPen
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUser, faLock, faPlus, faClipboard, faInfoCircle, faTrash,
  faKey, faSync, faPen
);
const history = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <Layout history={history}/>
    </Provider>
  );
}

export default App;
