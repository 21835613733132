import {
  PATIENTS_READNG,
  PATIENT_CREATION,
  PATIENT_UPDATING
} from '../actions/types';

const INITIAL_STATE = {
  items: null,
  totalCount: 1
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PATIENTS_READNG:
      return {
        ...state,
        items: action.patientList.items,
        totalCount: action.patientList.totalCount
      };

    case PATIENT_CREATION:
      return {
        ...state,
        items: [...state.items, action.patient],
      };

    case PATIENT_UPDATING:
      return {
        ...state,
        items: state.items.map(patient => {
          return (patient.id === action.patient.id)
            ? action.patient
            : patient
        })
      };

    default:
      return state
  }
}