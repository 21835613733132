import {
  USERS_READING,
  USER_CREATION,
  USER_DELETION,
  USER_UPDATING,
  USER_EDITING
} from '../actions/types';

const INITIAL_STATE = {
  items: null,
  totalCount: 1,
  editableUser: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USERS_READING:
      return {
        ...state,
        items: action.userList.items,
        totalCount: action.userList.totalCount
      };

    case USER_CREATION:
      return {
        ...state,
        items: [...state.items, action.user],
      };

    case USER_DELETION:
      return {
        ...state,
        items: state.items.filter(user => user.id !== action.user.id)
      };

    case USER_UPDATING:
      return {
        ...state,
        items: state.items.map(user => {
          return (user.id === action.user.id)
            ? action.user
            : user
        })
      };

    case USER_EDITING:
      return {
        ...state,
        editableUser: action.user
      }

    default:
      return state
  }
}