import React, {useRef, useEffect} from 'react';
import { connect } from 'react-redux'
import {Field, reduxForm} from 'redux-form';
import Input from '../Input/Input';
import Button from '../Button/Button';
import main from '../../containers/main';
import {required} from '../../utils/validation';
import './UserForm.scss';

let UserForm = (props) => {
  const {
    handleSubmit,
  } = props;

  let firstEl = useRef(null);

  useEffect(() => {
    firstEl.focus();
  }, []);

  return (
    <div className="user-form">
      <form onSubmit={handleSubmit} className="col-sm-12" noValidate autoComplete="off">
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">Email</label>
          <div className="col-sm-8">
            <Field
              name="email"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              getRef={input => {
                firstEl = input;
              }}
            />
          </div>
        </div>
        
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">First Name</label>
          <div className="col-sm-8">
            <Field
              name="firstName"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </div>
        </div>
        
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">Last Name</label>
          <div className="col-sm-8">
            <Field
              name="lastName"
              className="input"
              component={Input}
              type="text"
              validate={[required]}
              />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">Role</label>
          <div className="col-sm-8">
            <Field
              name="role"
              className="input"
              component="select"
              validate={[required]}
            >
              <option></option>
              <option value="user">user</option>
              <option value="admin">admin</option>
            </Field>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">Is Active</label>
          <div className="col-sm-8">
            <Field
              name="isActive"
              className="input"
              component="input"
              type="checkbox"
            />
          </div>
        </div>

        <hr/>

        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">Password</label>
          <div className="col-sm-8">
            <Field
              name="password"
              className="input"
              component="input"
              type="password"
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="name">Confirmation</label>
          <div className="col-sm-8">
            <Field
              name="passwordConfirmation"
              className="input"
              component="input"
              type="password"
            />
          </div>
        </div>

        <Button
            className="btn btn-primary submit-btn"
            type="submit"
          >
          Submit
        </Button>
      </form>
    </div>
  );
}
UserForm = reduxForm({
  form: 'UserForm'
})(UserForm)

UserForm = connect(
  state => ({
    initialValues: state.users.editableUser
  }),
)(UserForm)

export default main(UserForm);