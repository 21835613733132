import {connect} from 'react-redux';
import * as PracticeActions from '../actions/practices';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  practices: state.practices.items,
  totalCount: state.practices.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readPractices: practices => dispatch(PracticeActions.readPractices(practices)),
  createPractice: practice => dispatch(PracticeActions.createPractice(practice)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
