import React from 'react';
import PracticeWorkSchedulesList from '../../components/PracticeWorkSchedulesList/PracticeWorkSchedulesList';
import './PracticeWorkSchedule.scss';

function PracticeWorkSchedule (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Practice Work Schedule</h3>
      </div>
      <div className="row">
        <PracticeWorkSchedulesList />
      </div>
    </div>
  );
}
export default PracticeWorkSchedule;