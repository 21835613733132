import{ useEffect } from "react";
import main from '../../containers/main';

const Logout = (props) => {
  const {
    signOut,
    history,
  } = props;

  useEffect(() => {
    signOut();
    localStorage.clear();
    history.push('/login');
  })
  
  return null;
}

export default main(Logout);