import React from 'react';
import ProviderWeekSchedulesList from '../../components/ProviderWeekSchedulesList/ProviderWeekSchedulesList';
import './ProviderWeekSchedule.scss';

function ProviderWeekSchedule (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Provider Week Schedule</h3>
      </div>
      <div className="row">
        <ProviderWeekSchedulesList />
      </div>
    </div>
  );
}
export default ProviderWeekSchedule;