import React, {useState, useEffect, useCallback} from 'react';
import providerWorkSchedule from '../../containers/providerWorkSchedule';
import {Container, ButtonToolbar, Table, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ProviderSelect from '../ProviderSelect/ProviderSelect';
import Pagination from '../Pagination/Pagination';
import './ProviderWorkSchedulesList.scss';
import {NotificationContainer} from 'react-notifications';
import apiClient from '../../utils/apiClient';
import DatePicker from 'react-datepicker';
import moment from 'moment';

function ProviderWorkSchedulesList (props) {
  const {
    readProviderWorkSchedules,
    providerWorkSchedules,
    totalCount,
    setPreloader,
  } = props;

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getProviderWorkSchedules = useCallback(async (providerId, filter, pagination) => {
    setPreloader(true);
    try {
      const providerWorkSchedules = await apiClient.getProviderWorkSchedules(providerId, filter, pagination);
      return providerWorkSchedules;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    if (!selectedProvider || !selectedProvider.value) {
      readProviderWorkSchedules({items: [], totalCount: 0});
      return;
    }

    getProviderWorkSchedules(
      selectedProvider.value,
      {
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : null,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : null
      }, {
        pageNum,
        pageSize
      }
    ).then((response) => 
      readProviderWorkSchedules(response)
    );
  }, [
    setPreloader,
    getProviderWorkSchedules,
    pageNum,
    pageSize,
    readProviderWorkSchedules,
    selectedProvider,
    fromDate,
    toDate,
    isRefresh
  ]);

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <ProviderSelect
              className="filter-provider mr-2 mt-2"
              value={selectedProvider}
              onChange={(provider) => setSelectedProvider(provider)}
            />
            <DatePicker
              className="filter-date mr-2 mt-2"
              selected={fromDate}
              placeholderText="From..."
              isClearable={true}
              onChange={(date) => setFromDate(date)}
            />
            <DatePicker
              className="filter-date mr-2 mt-2"
              selected={toDate}
              placeholderText="To..."
              isClearable={true}
              onChange={(date) => setToDate(date)}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload data">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Day Kind</th>
              <th scope="col">Weekday</th>
              <th scope="col">From Time</th>
              <th scope="col">To Time</th>
            </tr>
          </thead>
          <tbody>
            {
              !providerWorkSchedules
              ? <tr><td>Loading...</td></tr>
              : providerWorkSchedules.length 
                ? providerWorkSchedules.map(el => (
                  <tr key={el.providerId + el.date + el.fromTime}>
                    <td>{el.date}</td>
                    <td>{el.dayKind}</td>
                    <td>{el.weekday}</td>
                    <td>{el.fromTime}</td>
                    <td>{el.toTime}</td>
                  </tr>
                  ))
                : <tr><td colSpan="5" className="text-center">No work schedule yet</td></tr>
            }
          </tbody>
        </Table>
      
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}
export default providerWorkSchedule(ProviderWorkSchedulesList);