import React from 'react';
import PracticeWeekSchedulesList from '../../components/PracticeWeekSchedulesList/PracticeWeekSchedulesList';
import './PracticeWeekSchedule.scss';

function PracticeWeekSchedule (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Practice Week Schedule</h3>
      </div>
      <div className="row">
        <PracticeWeekSchedulesList />
      </div>
    </div>
  );
}

export default PracticeWeekSchedule;