import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, access, ...rest }) => {
  const token = localStorage.getItem('token');
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch {
    // Do nothing.
  }
  
  return (
    <Route
    {...rest}
    render={(props) => (
      (token && user && access.indexOf(user.role) !== -1)
        ? <Component {...props}/>
        : <Redirect to={{pathname: '/login', state: {from: props.location}}} />
    )}
  />
  )
}

export default PrivateRoute;