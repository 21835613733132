import {connect} from 'react-redux';
import * as AuthActions from '../actions/auth';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  preloader: state.application.preloader,
})

const mapDispatchToProps = dispatch => ({
  signIn: (token, user) => dispatch(AuthActions.signIn(token, user)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
})
export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
