import {connect} from 'react-redux';
import * as UserActions from '../actions/users';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users.items,
  totalCount: state.users.totalCount,
  editableUser: state.users.editableUser,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readUsers: userList => dispatch(UserActions.readUsers(userList)),
  createUser: user => dispatch(UserActions.createUser(user)),
  updateUser: user => dispatch(UserActions.updateUser(user)),
  deleteUser: user => dispatch(UserActions.deleteUser(user)),
  editUser: user => dispatch(UserActions.editUser(user)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
