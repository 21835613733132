import {connect} from 'react-redux';
import * as AuthActions from '../actions/auth';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
  role: state.auth.role,
  preloader: state.application.preloader,
  logout: state.application.logout,
})

const mapDispatchToProps = dispatch => ({
  signIn: (token, user) => dispatch(AuthActions.signIn(token, user)),
  signOut: () => dispatch(AuthActions.signOut()),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
  setLogout: logout => dispatch(ApplicationActions.setLogout(logout)),
  hideNotification: index => dispatch(AuthActions.hideNotification(index)),
})

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)
