import {
  API_CLIENTS_READING,
  API_CLIENT_CREATION,
  API_CLIENT_DELETION,
  API_CLIENT_UPDATING
} from '../actions/types';

const INITIAL_STATE = {
  items: null,
  totalCount: 1
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case API_CLIENTS_READING:
      return {
        ...state,
        items: action.apiClientList.items,
        totalCount: action.apiClientList.totalCount
      };

    case API_CLIENT_CREATION:
      return {
        ...state,
        items: [...state.items, action.apiClient],
      };

    case API_CLIENT_DELETION:
      return {
        ...state,
        items: state.items.filter(apiClient => apiClient.id !== action.apiClient.id)
      };

    case API_CLIENT_UPDATING:
      return {
        ...state,
        items: state.items.map(apiClient => {
          return (apiClient.id === action.apiClient.id)
            ? action.apiClient
            : apiClient
        })
      };

    default:
      return state
  }
}