import {connect} from 'react-redux';
import * as PatientActions from '../actions/patients';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  patients: state.patients.items,
  totalCount: state.patients.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readPatients: patients => dispatch(PatientActions.readPatients(patients)),
  createPatient: patient => dispatch(PatientActions.createPatient(patient)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
