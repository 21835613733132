import React, {useState, useEffect, useCallback} from 'react';
import appointment from '../../containers/appointment';
import {Container, ButtonToolbar, Table, ToggleButtonGroup, ToggleButton, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PatientSelect from '../PatientSelect/PatientSelect';
import ProviderSelect from '../ProviderSelect/ProviderSelect';
import PracticeSelect from '../PracticeSelect/PracticeSelect';
import Pagination from '../Pagination/Pagination';
import './AppointmentsList.scss';
import {NotificationContainer} from 'react-notifications';
import apiClient from '../../utils/apiClient';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Info from '../Info/Info';

function AppointmentsList (props) {
  const {
    readAppointments,
    appointments,
    totalCount,
    setPreloader,
  } = props;

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [appointmentId, setAppointmentId] = useState(0);
  const [appointmentExternalId, setAppointmentExternalId] = useState('');
  const [appointmentSmylenId, setAppointmentSmylenId] = useState(0);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isWriteback, setIsWriteback] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getAppointments = useCallback(async (filter, order, pagination) => {
    setPreloader(true);
    try {
      const appointments = await apiClient.getAppointments(filter, order, pagination);
      return appointments;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    getAppointments(
      {
        id: appointmentId,
        externalId: appointmentExternalId,
        smylenId: appointmentSmylenId,
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : null,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : null,
        isWriteback,
        patientId: selectedPatient ? selectedPatient.value : null,
        providerId: selectedProvider ? selectedProvider.value : null,
        practiceId: selectedPractice ? selectedPractice.value : null
      },
      null,
      {
        pageNum,
        pageSize
      }
    ).then((response) => 
    readAppointments(response)
    );
  }, [
    setPreloader,
    getAppointments,
    pageNum,
    pageSize,
    readAppointments,
    appointmentId,
    appointmentExternalId,
    appointmentSmylenId,
    fromDate,
    toDate,
    isWriteback,
    selectedPatient,
    selectedProvider,
    selectedPractice,
    isRefresh
  ]);

  let filterTimeout;
  const onFilterChange = (callback) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() =>{
      callback();
      setPageNum(1);
    }, 750);
  }

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <input
              className="filter-id mr-2 mt-2"
              type="search"
              placeholder="ID..."
              onChange={(e) => onFilterChange(setAppointmentId.bind(null, e.target.value))}
            />
            <input
              className="filter-external-id mr-2 mt-2"
              type="search"
              placeholder="Ext ID..."
              onChange={(e) => onFilterChange(setAppointmentExternalId.bind(null, e.target.value))}
            />
            <input
              className="filter-smylen-id mr-2 mt-2"
              type="search"
              placeholder="Smyl ID..."
              onChange={(e) => onFilterChange(setAppointmentSmylenId.bind(null, e.target.value))}
            />
            <DatePicker
              className="filter-date mr-2 mt-2"
              selected={fromDate}
              placeholderText="From..."
              isClearable={true}
              onChange={(date) => setFromDate(date)}
            />
            <DatePicker
              className="filter-date mr-2 mt-2"
              selected={toDate}
              placeholderText="To..."
              isClearable={true}
              onChange={(date) => setToDate(date)}
            />
            <ToggleButtonGroup
              type="checkbox"
              className="filter-toggle mr-2 mt-2"
              onChange={(isWriteback) => setIsWriteback(isWriteback.length && isWriteback[0])}
            >
              <ToggleButton value={true} variant="light">Writebacks</ToggleButton>
            </ToggleButtonGroup>
            <PatientSelect
              className="filter-patient mr-2 mt-2"
              value={selectedPatient}
              onChange={(patient) => setSelectedPatient(patient)}
            />
            <ProviderSelect
              className="filter-provider mr-2 mt-2"
              value={selectedProvider}
              onChange={(provider) => setSelectedProvider(provider)}
            />
            <PracticeSelect
              className="filter-practice mr-2 mt-2"
              value={selectedPractice}
              onChange={(practice) => setSelectedPractice(practice)}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload data">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">External ID</th>
              <th scope="col">Smylen ID</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Length</th>
              <th scope="col">Deleted</th>
              <th scope="col">Canceled</th>
              <th scope="col">Writeback</th>
              <th scope="col">Patient ID</th>
              <th scope="col">Provider ID</th>
              <th scope="col">Practice ID</th>
            </tr>
          </thead>
          <tbody>
            {
              !appointments
              ? <tr><td>Loading...</td></tr>
              : appointments.length 
                ? appointments.map(el => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td>{el.externalId}</td>
                    <td>{el.smylenId}</td>
                    <td>{el.appointmentStartDate}</td>
                    <td>{el.appointmentEndDate}</td>
                    <td>{el.appointmentLength}</td>
                    <td>{el.isDeleted ? <input type="checkbox" checked={el.isDeleted} disabled/> : null}</td>
                    <td>{el.isCanceled ? <input type="checkbox" checked={el.isCanceled} disabled/> : null}</td>
                    <td style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div style={{display: 'inline'}}>
                        {el.isWriteback ? <input type="checkbox" checked={el.isWriteback} disabled/> : null}
                      </div>
                      {el.writebackResult ? <Info text={el.writebackResult}/> : null}
                    </td>
                    <td>{el.patientId}</td>
                    <td>{el.providerId}</td>
                    <td>{el.practiceId}</td>
                  </tr>
                  ))
                : <tr><td colSpan="12" className="text-center">No appointments yet</td></tr>
            }
          </tbody>
        </Table>
      
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}
export default appointment(AppointmentsList);