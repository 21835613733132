import React, {useState, useEffect, useCallback} from 'react';
import apiClient from '../../containers/apiClient';
import {Container, ButtonToolbar, ButtonGroup, Button, Table} from 'react-bootstrap';
import Pagination from '../Pagination/Pagination';
import './ApiClientsList.scss';
import {NotificationContainer} from 'react-notifications';
import createNotification from '../Notification/Notification';
import adminApiClient from '../../utils/apiClient';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalAddApiClinet from './ModalAddApiClient/ModalAddApiClient';

function ApiClientsList(props) {
  const {
    auth,
    readApiClients,
    createApiClient,
    updateApiClient,
    deleteApiClient,
    apiClients,
    totalCount,
    setPreloader,
  } = props;

  const [apiClientId, setApiClientId] = useState(0);
  const [apiClientCompany, setApiClientCompany] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getApiClients = useCallback(async (filter, order, pagination) => {
    setPreloader(true);
    try {
      const apiClients = await adminApiClient.getApiClients(filter, order, pagination);
      return apiClients;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  const [showModalAddApiClient, setShowModalAddApiClient] = useState(false);
  const handleCloseModalAddApiClient = () => setShowModalAddApiClient(false);
  const handleShowModalAddApiClient = () => setShowModalAddApiClient(true);
  const handleSubmitModalAddApiClient = async (prop) => {
    setPreloader(true);
    try {
      const apiClient = await adminApiClient.addApiClient(prop);
      createApiClient(apiClient);

      createNotification({type: 'success', message: 'API Client was successfully added'});
    } catch (err) {
      console.log(err);
      createNotification({type: 'error', message: err.message});
    } finally {
      setPreloader(false);
    }
    handleCloseModalAddApiClient();
  }

  useEffect(() => {
    getApiClients({
      id: apiClientId,
      company: apiClientCompany
    }, null, {pageNum, pageSize}).then((response) => readApiClients(response));
  }, [
    getApiClients,
    apiClientId,
    apiClientCompany,
    pageNum,
    pageSize,
    readApiClients,
    isRefresh
  ]);

  let filterTimeout;
  const onFilterChange = (callback) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() =>{
      callback();
      setPageNum(1);
    }, 750);
  }

  // const disableApiClient = (id) => {

  // }
  
  // const enableApiClient = (id) => {

  // }

  const handleApiKeyRegenration = async (id) => {
    if (!window.confirm("Regenerate API Key?")) {
      return;
    }

    setPreloader(true);
    try {
      const apiClient = await adminApiClient.regenerateApiKey(id);
      updateApiClient(apiClient);

      createNotification({type: 'success', message: "API Key was successfully regenerated"});
    } catch (err) {
      console.log(err);
      createNotification({type: 'error', message: err.message});
    } finally {
      setPreloader(false);
    }
  }

  const handleApiClientDeleteion = async (id) => {
    if (!window.confirm("Delete API Client?")) {
      return;
    }

    setPreloader(true);
    try {
      const apiClient = await adminApiClient.deleteApiClient(id);
      deleteApiClient(apiClient);

      createNotification({type: 'success', message: "API Client was successfully deleted"});
    } catch (err) {
      console.log(err);
      createNotification({type: 'error', message: err.message});
    } finally {
      setPreloader(false);
    }
  }

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid ">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <input
              className="filter-id mr-2 mt-2"
              type="search"
              placeholder="ID..."
              onChange={(e) => onFilterChange(setApiClientId.bind(null, e.target.value))}
            />
            <input
              className="filter-email mr-2 mt-2"
              type="search"
              placeholder="Company..."
              onChange={(e) => onFilterChange(setApiClientCompany.bind(null, e.target.value))}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            {auth.user && auth.user.role === 'admin' && 
              <Button variant="success" onClick={handleShowModalAddApiClient} className="mt-2 mr-2" title="Add API Client">
                <FontAwesomeIcon icon="plus"/>
              </Button>
            }
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload list">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Company</th>
              <th scope="col">API Key</th>
              <th scope="col">Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              !apiClients
              ? <tr><td>Loading...</td></tr>
              : apiClients.length 
                ? apiClients.map(el => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td className="nowrap">
                      {el.company}
                      <CopyToClipboard text={el.company} className="btn-copy ml-2">
                        <Button variant="light" title="Copy to clipboard"><FontAwesomeIcon icon="clipboard"/></Button>
                      </CopyToClipboard>
                    </td>
                    <td className="nowrap">
                      <span title={el.apiKey}>{el.apiKey.substr(0, 10)} ... {el.apiKey.substr(el.apiKey.length - 10)}</span>
                      <CopyToClipboard text={el.apiKey} className="btn-copy ml-2">
                        <Button variant="light" title="Copy to clipboard"><FontAwesomeIcon icon="clipboard"/></Button>
                      </CopyToClipboard>
                    </td>
                    <td>
                      {el.isActive ? <input type="checkbox" checked={el.isActive} disabled/> : null}
                    </td>
                    <td className="btn-row">
                      {auth.user.role === 'admin' && 
                        <ButtonToolbar>
                          <ButtonGroup>
                            {/* {el.enable
                              ? <Button variant="primary" onClick={() => disableApiClient(el.id)}>Disable</Button>
                              : <Button variant="success" onClick={() => enableApiClient(el.id)}>Enable</Button>
                            } */}
                              <Button variant="primary" onClick={() => handleApiKeyRegenration(el.id)} title="Regenerate API Key"><FontAwesomeIcon icon="key"/></Button>
                              <Button variant="danger" onClick={() => handleApiClientDeleteion(el.id)} title="Delete API Client"><FontAwesomeIcon icon="trash"/></Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      }
                    </td>
                  </tr>
                  ))
                : <tr><td colSpan="5" className="text-center">No API clients yet</td></tr>
            }
          </tbody>
        </Table>
      
        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>

      <ModalAddApiClinet
        show={showModalAddApiClient}
        onSubmit={handleSubmitModalAddApiClient}
        onHide={handleCloseModalAddApiClient}
      ></ModalAddApiClinet>
    </div>
  );
}

export default apiClient(ApiClientsList);