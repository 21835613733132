
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import apiClient from '../../utils/apiClient';

export default class PatientSelect extends Component {
  getOptions(inputValue, callback) {
    apiClient
      .getPatients({fullName: inputValue}, null, {pageNum: 1, pageSize: 10})
      .then(response => {
        callback(response.items.map(p => ({value: p.id, label: p.firstName + ' ' + p.lastName})))
      })
      .catch(err => {
        console.error(err);
        callback([]);
      })
  }
  
  render() {
    return (
      <AsyncSelect 
        {...this.props}
        cacheOptions
        defaultOptions
        loadOptions={_.debounce(this.getOptions.bind(this), 450)}
        placeholder="Patient..."
        isSearchable={true}
        isClearable={true}
      />
    );
  }
}
