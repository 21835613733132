import {connect} from 'react-redux';
import * as IntegrationActions from '../actions/integrations';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  integrations: state.integrations.items,
  totalCount: state.integrations.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readIntegrations: integrations => dispatch(IntegrationActions.readIntegrations(integrations)),
  createIntegration: integration => dispatch(IntegrationActions.createIntegration(integration)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
