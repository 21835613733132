const apiUrl = (
  process.env.REACT_APP_API_PROTOCOL + '://' +
  process.env.REACT_APP_API_HOST +
  (
      process.env.REACT_APP_API_PORT
    ? ':' + process.env.REACT_APP_API_PORT
    : ''
  ) +
  '/api/admin/v1'
);

export default {
  apiUrl
};

