import React from 'react';
import { NavLink } from 'react-router-dom';
import main from '../../containers/main';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import NotificationTooltip from '../NotificationTooltip/NotificationTooltip';
import './Navigation.scss';

const Navigation = (props) => {
  const {
    token,
    history,
  } = props;

  
  return (
    <Navbar className="navigation justify-content-center"  variant="dark" expand="lg">
      <Navbar.Brand href="#">Smylen Book</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto navigation__nav-block" >
        {token
            ? <>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/users`}>Users</NavLink>
              </li>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/apiClients`}>API Clients</NavLink>
              </li>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/integrations`}>Integrations</NavLink>
              </li>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/practices`}>Practices</NavLink>
              </li>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/operatories`}>Operatories</NavLink>
              </li>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/providers`}>Providers</NavLink>
              </li>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/patients`}>Patients</NavLink>
              </li>
              <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/appointments`}>Appointments</NavLink>
              </li>
              <NavDropdown title="Schedules">
                <NavDropdown title="Practice">
                  <NavDropdown.Item href={`/practice-week-schedule`}>Week Schedule</NavDropdown.Item>
                  <NavDropdown.Item href={`/practice-work-schedule`}>Work Schedule</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Provider">
                  <NavDropdown.Item href={`/provider-week-schedule`}>Week Schedule</NavDropdown.Item>
                  <NavDropdown.Item href={`/provider-work-schedule`}>Work Schedule</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>
            </>
            : null
          }
        </Nav>
        <Nav className="ml-auto navigation__nav-block" >
          {token
            ? <NotificationTooltip history={history}/>
            : null
          }
          {/* {token
            ? <li className="navigation_item nav-item">
                <NavLink className="nav-link" to={`/profile`}>Profile</NavLink>
              </li>
            : null
          } */}
          {token
            ? <li className="navigation_item nav-item">
                <NavLink className="nav-link" to='/logout'>Sign Out</NavLink>
              </li>
            : <li className="navigation_item nav-item">
                <NavLink  className="nav-link" to={`/login`}>Sign In</NavLink>
              </li>
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default main(Navigation);
