import React, {useState, useEffect, useCallback} from 'react';
import user from '../../containers/user';
import { Container, Button, ButtonToolbar, ButtonGroup, Table } from 'react-bootstrap';
import './UsersList.scss';
import createNotification from '../Notification/Notification';
import Pagination from '../Pagination/Pagination';
import {NotificationContainer} from 'react-notifications';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import apiClient from '../../utils/apiClient';
import ModalAddUser from './ModalAddUser/ModalAddUser';

function UsersList (props) {
  const {
    auth,
    readUsers,
    createUser,
    updateUser,
    deleteUser,
    editUser,
    editableUser,
    users,
    totalCount,
    setPreloader,
  } = props;

  const [userId, setUserId] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefresh, setRefresh] = useState(false);

  const getUsers = useCallback(async (filter, order, pagination) => {
    setPreloader(true);
    try {
      const users = await apiClient.getUsers(filter, order, pagination);
      return users;
    } catch (err) {
      console.error(err);
      return [];
    } finally {
      setPreloader(false);
    }
  }, [setPreloader]);

  useEffect(() => {
    getUsers(
      {
        id: userId,
        email: userEmail,
        fullName: userFullName,
      },
      null,
      {
        pageNum,
        pageSize
      }
    ).then((response) => readUsers(response));
  }, [
    getUsers,
    userId,
    userEmail,
    userFullName,
    pageNum,
    pageSize,
    readUsers,
    isRefresh
  ]);

  let filterTimeout;
  const onFilterChange = (callback) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() =>{
      callback();
      setPageNum(1);
    }, 750);
  }

  const [showModalAddUser, setShowModalAddUser] = useState(false);
  const handleCloseModalAddUser = () => setShowModalAddUser(false);
  const handleShowModalAddUser = (user) => {
    editUser(user);
    setShowModalAddUser(true)
  };

  const handleUserCreation = async (props) => {
    setPreloader(true);
    try {
      const user = await apiClient.addUser(props);
      createUser(user);

      handleCloseModalAddUser();
      createNotification({type: 'success', message: 'User was successfully added'});
    } catch (err) {
      console.log(err);
      createNotification({type: 'error', message: err.message});
    } finally {
      setPreloader(false);
    }
  }

  const handleUserUpdating = async (props) => {
    setPreloader(true);
    try {
      const user = await apiClient.editUser(props.id, props);
      updateUser(user);

      handleCloseModalAddUser();
      createNotification({type: 'success', message: "User was successfully updated"});
    } catch (err) {
      console.log(err);
      createNotification({type: 'error', message: err.message});
    } finally {
      setPreloader(false);
    }
  }

  const handleUserDeleteion = async (id) => {
    if (!window.confirm("Delete user?")) {
      return;
    }

    setPreloader(true);
    try {
      const user = await apiClient.deleteUser(id);
      deleteUser(user);

      createNotification({type: 'success', message: "User was successfully deleted"});
    } catch (err) {
      console.log(err);
      createNotification({type: 'error', message: err.message});
    } finally {
      setPreloader(false);
    }
  }

  const toggleUser = async (props) => {
    try {
      props.isActive = !props.isActive;
      const user = await apiClient.editUser(props.id, props);
      updateUser(user);

      createNotification({type: 'success', message: "User was successfully toggled"});
    } catch (err) {
      console.log(err);
      createNotification({type: 'error', message: err.message});
    }
  }

  const handleRefresh = () => {
    setRefresh(!isRefresh);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <NotificationContainer/>
        <Container className="toolbar-container">
          <ButtonToolbar className="float-left">
            <input
              className="filter-id mr-2 mt-2"
              type="search"
              placeholder="ID..."
              onChange={(e) => onFilterChange(setUserId.bind(null, e.target.value))}
            />
            <input
              className="filter-email mr-2 mt-2"
              type="search"
              placeholder="Email..."
              onChange={(e) => onFilterChange(setUserEmail.bind(null, e.target.value))}
            />
            <input
              className="filter-user-name mr-2 mt-2"
              type="search"
              placeholder="Full Name..."
              onChange={(e) => onFilterChange(setUserFullName.bind(null, e.target.value))}
            />
          </ButtonToolbar>
          <ButtonToolbar className="float-right">
            {auth.user && auth.user.role === 'admin' &&
              <Button variant="success" onClick={() => handleShowModalAddUser()} className="mt-2 mr-2" title="Add User">
                <FontAwesomeIcon icon="plus"/>
              </Button>
            }
            <Button variant="primary" onClick={handleRefresh} className="mt-2" title="Reload list">
              <FontAwesomeIcon icon="sync"/>
            </Button>
          </ButtonToolbar>
        </Container>
        <Table responsive style={{ background: 'white' }}>
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Email</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Role</th>
              <th scope="col">Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              !users
              ? <tr><td>Loading...</td></tr>
              : users.length 
                ? users.map(el => (
                  <tr key={el.id}>
                    <td>{el.id}</td>
                    <td className="nowrap">
                      {el.email}
                      <CopyToClipboard text={el.email} className="btn-copy ml-2">
                        <Button variant="light" title="Copy to clipboard"><FontAwesomeIcon icon="clipboard"/></Button>
                      </CopyToClipboard>
                    </td>
                    <td>{el.firstName}</td>
                    <td>{el.lastName}</td>
                    <td>{el.role}</td>
                    <td>
                      <input type="checkbox" checked={el.isActive} onChange={() => toggleUser(el)}/>
                    </td>
                    <td className="btn-row">
                      {auth.user.role === 'admin' &&
                        <ButtonToolbar>
                          <ButtonGroup>
                            <Button variant="success" onClick={() => handleShowModalAddUser(el)} title="Update user"><FontAwesomeIcon icon="pen"/></Button>
                            <Button variant="danger" onClick={() => handleUserDeleteion(el.id)} title="Delete user" disabled={el.isSystem}><FontAwesomeIcon icon="trash"/></Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      }
                    </td>
                  </tr>
                  ))
                : <tr><td colSpan="7" className="text-center">No users yet</td></tr>
            }
          </tbody>
        </Table>

        <Pagination
          pageNum={pageNum}
          pageSize={pageSize}
          pageCount={Math.ceil(totalCount / pageSize)}
          pageNeighbours={2}
          setPageNum={setPageNum}
          setPageSize={setPageSize}
        />
      </div>
      
      <ModalAddUser
        show={showModalAddUser}
        onSubmit={editableUser ? handleUserUpdating : handleUserCreation}
        onHide={handleCloseModalAddUser}
      ></ModalAddUser>
    </div>
  );
}
export default user(UsersList);