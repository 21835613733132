import {connect} from 'react-redux';
import * as PracticeWeekScheduleActions from '../actions/practiceWeekSchedules';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  practiceWeekSchedules: state.practiceWeekSchedules.items,
  totalCount: state.practiceWeekSchedules.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readPracticeWeekSchedules: practiceWeekSchedules => dispatch(PracticeWeekScheduleActions.readPracticeWeekSchedules(practiceWeekSchedules)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
