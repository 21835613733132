import {connect} from 'react-redux';
import * as ProviderWeekScheduleActions from '../actions/providerWeekSchedules';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  providerWeekSchedules: state.providerWeekSchedules.items,
  totalCount: state.providerWeekSchedules.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readProviderWeekSchedules: providerWeekSchedules => dispatch(ProviderWeekScheduleActions.readProviderWeekSchedules(providerWeekSchedules)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
