import React from 'react';
import Input from '../Input/Input';
import { Button } from 'react-bootstrap';
import main from '../../containers/main';
import {Field, reduxForm} from 'redux-form';
import {required} from '../../utils/validation';
import './UpdatePasswordForm.scss';

let UpdatePasswordForm = (props) => {
  const { handleSubmit } = props;
  
  return (
    <div className="password-form">
      <form onSubmit={handleSubmit} className="col-sm-12" noValidate>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="password">Old Password</label>
          <div className="col-sm-8">
            <Field
              name="password"
              className="input"
              component={Input}
              type="password"
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="newPassword">New Password</label>
          <div className="col-sm-8">
            <Field
              name="newPassword"
              className="input"
              component={Input}
              type="password"
              validate={[required]}
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-4 col-form-label"></label>
          <div className="col-sm-8">
            <Button
              variant="primary"
              type="submit"
              >
              Submit
            </Button>
          </div>
        </div>
        
      </form>
    </div>
  );
}
UpdatePasswordForm = reduxForm({
  form: 'UpdatePasswordForm'
})(UpdatePasswordForm)

export default main(UpdatePasswordForm);