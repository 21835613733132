import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_USER_UPDATING,
  HIDE_NOTIFICATION
} from '../actions/types';

const INITIAL_STATE = {
  token: null,
  user: null,
  role: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return {
        ...state,
        token: action.token,
        user: action.user,
        role: action.user.role
      };

    case AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        role: null
      };

    case AUTH_USER_UPDATING:
      return {
        ...state,
        user: action.user
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        user: {
          ...state.user,
          notifications: (state.user.notifications
            ? state.user.notifications.filter((notification, index)=> {
              return (index !== action.index)
            })
            : []
          )
        }
      };

    default:
      return state;
  }
}