import React from 'react';
import { Form, FormControl, Container, Col, Pagination as BootstrapPagination } from 'react-bootstrap';

/**
 * @see https://scotch.io/tutorials/build-custom-pagination-with-react
 */
function Pagination(props) {
  let {
    pageNum,
    pageSize,
    pageCount,
    pageNeighbours,
    setPageNum,
    setPageSize
  } = props;

  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
  
    while (i <= to) {
      range.push(i);
      i += step;
    }
  
    return range;
  }
   
  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const generatePages = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (pageCount > totalBlocks) {
      const startPage = Math.max(2, pageNum - pageNeighbours);
      const endPage = Math.min(pageCount - 1, pageNum + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (pageCount - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }      
      return [1, ...pages, pageCount];
    }

    return range(1, pageCount);
}

  const pagination = () => {
    if (pageCount === 1) {
      return (
        <BootstrapPagination.Item key={1} onClick={() => setPageNum(1)} active={1 === pageNum }>
          {1}
        </BootstrapPagination.Item>
      );
    }

    let pages = generatePages();
    return pages.map(page => {
      if (page === LEFT_PAGE) {
        return (<BootstrapPagination.Prev key="prev" onClick={() => setPageNum(1 < pageNum ? pageNum - (pageNeighbours * 2) - 1 : 1)}/>);
      }
      if (page === RIGHT_PAGE) {
        return (<BootstrapPagination.Next key="next" onClick={() => setPageNum(pageNum < pageCount ? pageNum + (pageNeighbours * 2) + 1 : pageCount)}/>);
      }
      return (
        <BootstrapPagination.Item key={page} onClick={() => setPageNum(page)} active={page === pageNum }>
          {page}
        </BootstrapPagination.Item>
      );
    });
  }

  return (
    <Container className="pagination-container">
      <Form>
        <Form.Row>
          <Col>
        <BootstrapPagination>
          {pagination()}
        </BootstrapPagination>
        </Col>
        <Col>
        <FormControl className="page-size" as="select" onChange={(e) => setPageSize(e.target.value)} value={pageSize}>
          {[5, 10, 25, 50, 100].map(el => (
            <option key={el} value={el}>{el}</option>
          ))}
        </FormControl>
        </Col>
        </Form.Row>
      </Form>
    </Container>
  );
}

export default Pagination;