import {NotificationManager} from 'react-notifications';

const notificationTimeout = 3000;

const Notification = (data) => {
  switch (data.type) {
    case 'info':
      NotificationManager.info(`${data.message}`, '', notificationTimeout);
      break;

    case 'success':
      NotificationManager.success(`${data.message}`, '', notificationTimeout);
      break;
      
    case 'warning':
      NotificationManager.warning(`${data.message}`, '', notificationTimeout);
      break;

    case 'error':
      NotificationManager.error(`${data.message}`, '', notificationTimeout);
      break;

    default:
      break;
  }
}

export default Notification;