//import {SubmissionError} from 'redux-form';
//import {RESET_PASSWORD} from '../../config/index';

export default async (data) => {
  // const body = {
  //   userData: data
  // }
  // const response = await fetch(`${RESET_PASSWORD}`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${data.token}`
  //   },
  //   body: JSON.stringify(body),
  // });
  // try {
  //   return await response.json();
  // } catch (e) {
  //   throw new SubmissionError(e);
  // }
};
