import React from 'react';
import UserForm from '../../UserForm/UserForm';
import { Modal } from 'react-bootstrap';
import main from '../../../containers/main';
import './styles.scss';

function ModalAddUser(props) {
  const {
    show,
    onHide,
    onSubmit,
  } = props;

  return (
    <Modal show={show} onHide={onHide} autoFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserForm onSubmit={onSubmit}></UserForm>
      </Modal.Body>
    </Modal>
  );
};

export default main(ModalAddUser);
