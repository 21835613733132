// import {SubmissionError} from 'redux-form';
// import parseResponse from 'utils/parseResponse';
// import {SIGN_IN_API} from 'config';
//import {RESTORE_PASSWORD} from '../../../config/index';

export default async (data) => {
  // const body = {
  //   login: data.login
  // }
  // const response = await fetch(`${RESTORE_PASSWORD}`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(body),
  // });
  // try {
  //   return await response.json();
  // } catch (e) {
  //   throw new SubmissionError(e);
  // }
};
