import React, { useState} from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import apiClient from '../../utils/apiClient';
import main from '../../containers/main';
import './Login.scss';

const Login = (props) => {
  const {
    signIn,
    setPreloader,
    history,
    setLogout
  } = props;

  const [error, setError] = useState(null);

  const submit = async (data) => {
    setPreloader(true);
    try {
      const loginResponse = await apiClient.login(data.login, data.password);
      
      setLogout(false);
      localStorage.setItem('token', loginResponse.token);
      localStorage.setItem('user', JSON.stringify(loginResponse.user));
      signIn(loginResponse.token, loginResponse.user);

      setTimeout(()=> {history.push('/integrations')})
    } catch (err) {
      setError(err.message);
    } finally {
      setPreloader(false);
    }
  }

  return (
    <div className="container login">
      <LoginForm onSubmit={submit} submitError={error}></LoginForm>
    </div>
  );
}

export default main(Login);