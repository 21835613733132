import React from 'react';
import ProviderWorkSchedulesList from '../../components/ProviderWorkSchedulesList/ProviderWorkSchedulesList';
import './ProviderWorkSchedule.scss';

function ProviderWorkSchedule (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Provider Work Schedule</h3>
      </div>
      <div className="row">
        <ProviderWorkSchedulesList />
      </div>
    </div>
  );
}
export default ProviderWorkSchedule;