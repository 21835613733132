import React from 'react';
import PracticesList from '../../components/PracticesList/PracticesList';
import './Practices.scss';

function Practices (props) {
  return (
    <div className="container page-body">
      <div className="row page-header">
        <h3>Practices</h3>
      </div>
      <div className="row">
        <PracticesList />
      </div>
    </div>
  );
}
export default Practices;