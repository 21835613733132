import {connect} from 'react-redux';
import * as AppointmentActions from '../actions/appointments';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  appointments: state.appointments.items,
  totalCount: state.appointments.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readAppointments: appointments => dispatch(AppointmentActions.readAppointments(appointments)),
  createAppointment: appointment => dispatch(AppointmentActions.createAppointment(appointment)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
