import {connect} from 'react-redux';
import * as OperatoryActions from '../actions/operatories';
import * as ApplicationActions from '../actions/application';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  operatories: state.operatories.items,
  totalCount: state.operatories.totalCount,
  preloader: state.application.preloader
});

const mapDispatchToProps = dispatch => ({
  readOperatories: operatories => dispatch(OperatoryActions.readOperatories(operatories)),
  setPreloader: preloader => dispatch(ApplicationActions.setPreloader(preloader)),
});

export default Component => connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
