//import {SubmissionError} from 'redux-form';
//import {UPDATE_USER} from '../../config/index';

export default async (data, token) => {
  // const response = await fetch(`${UPDATE_USER}`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${token}`
  //   },
  //   body: JSON.stringify(data),
  // });
  // try {
  //   return await response.json();
  // } catch (e) {
  //   throw new SubmissionError(e);
  // }
};
