import {
  PRACTICES_READNG,
  PRACTICE_CREATION,
  PRACTICE_UPDATING
} from '../actions/types';

const INITIAL_STATE = {
  items: null,
  totalCount: 1
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRACTICES_READNG:
      return {
        ...state,
        items: action.practiceList.items,
        totalCount: action.practiceList.totalCount
      };

    case PRACTICE_CREATION:
      return {
        ...state,
        items: [...state.items, action.practice],
      };

    case PRACTICE_UPDATING:
      return {
        ...state,
        items: state.items.map(practice => {
          return (practice.id === action.practice.id)
            ? action.practice
            : practice
        })
      };

    default:
      return state
  }
}